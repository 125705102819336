import iconSheet from '@/assets/images/icons.svg';

// icons to build ( please keep this list alphabetically sorted)
export const icons = [
  '3d-identifier',
  'airplane',
  'alert-circle',
  'alert-triangle',
  'all-filters',
  'arrow-carrot-up-and-down',
  'arrow-up-and-down-custom',
  'arrow-left',
  'arrow-right',
  'arrow-left-custom',
  'arrow-right-custom',
  'arrow-up',
  'arrow-up-custom',
  'average',
  'banquet',
  'bed',
  'bed-custom',
  'bolt',
  'buffet',
  'boardroom',
  'building-icon',
  'building-icon-filled',
  'bus',
  'cabaret',
  'calculator',
  'calendar',
  'canvas-ruler',
  'canvas-ruler-custom',
  'camera-icon',
  'capacity-icon',
  'car',
  'cart',
  'check',
  'check-circle',
  'checkbox',
  'checkbox-empty',
  'checkbox-solid',
  'chevron',
  'chevron-up',
  'chevrons-up',
  'chevron-left',
  'chevron-right',
  'chevron-down',
  'chevrons-down',
  'classroom',
  'clock',
  'cocktail',
  'contacts',
  'copy-rfp',
  'cocktail',
  'computer',
  'crescent',
  'custom-contacts',
  'custom-first-aid',
  'custom-map-pin-2',
  'custom-message-bubble',
  'custom-tree',
  'custom-users',
  'diamond-empty',
  'diamond-filled',
  'diamond-half',
  'document',
  'dollar-sign',
  'download',
  'download-custom',
  'e-shaped',
  'edit-pencil',
  'external-link',
  'eight-by-ten-exhibits',
  'event',
  'facebook',
  'faq-all-image',
  'faq-complete-all-image',
  'event',
  'first-aid',
  'fitness',
  'floorplan',
  'floorplan-custom',
  'full-map',
  'group',
  'hamburger-menu',
  'handicap',
  'heart',
  'heart-fill',
  'hotel',
  'hotel-custom',
  'hollow-square',
  'info',
  'info-custom',
  'instagram',
  'instant-book-now',
  'island-exhibits',
  'key',
  'layout-custom',
  'list',
  'lightbulb',
  'magic-wand',
  'mail',
  'map-center-pin',
  'map-pin',
  'custom-map-pin-2',
  'map-view',
  'maximize',
  'maximize-custom',
  'max-capacity',
  'medium-map',
  'message-bubble',
  'microphone-on',
  'minimize',
  'minus',
  'minus-circle',
  'more-horizontal',
  'more-vertical',
  'new-rfp',
  'office-building',
  'office-chair',
  'office-chair-custom',
  'old-phone',
  'paper',
  'paper-custom',
  'parking',
  'peninsula-exhibits',
  'people-group',
  'people-group-custom',
  'perimeter-exhibits',
  'perimeter-seating',
  'person-custom',
  'play-video',
  'play-video-icon',
  'plus',
  'plus-circle',
  'resource',
  'royal',
  're-center',
  'search',
  'send-rfp',
  'session',
  'settings',
  'shopping-bag',
  'small-map',
  'speed',
  'star',
  'star-fill',
  'star-half',
  'sun',
  't-shaped',
  'table-exhibits',
  'tag',
  'talk-show',
  'ten-by-ten-exhibits',
  'theater',
  'three-dimensional',
  'thumbs-up',
  'trash',
  'tree',
  'trophy',
  'twitter',
  'u-shaped',
  'users',
  'utencils',
  'utencils-custom',
  'video-play',
  'view-3d',
  'view3d-custom',
  'weather',
  'website-icon',
  'x'
] as const;

export type IconId = (typeof icons)[number];

interface Props {
  id: IconId;
  title?: string;
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
  className?: string;
  testID?: string;
}

// Svg Icon Component tied to a pre-built sprite sheet (icons.svg)

export default function Icon({ id, title, testID, ...props }: Props) {
  const areaLabelProps = title ? { 'aria-label': title } : { 'aria-hidden': true };
  return (
    <svg {...props} data-cvent-id={testID ?? `${id}-icon`} {...areaLabelProps}>
      <use href={`${iconSheet.src}#${id}`} />
    </svg>
  );
}
